import React, { useState, useEffect, useMemo } from "react";
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
} from "@tanstack/react-table";
import {
	DropdownButton,
	Dropdown,
	Table,
	Pagination,
	Row,
	Col,
} from "react-bootstrap";
import { Link } from "gatsby";

const RecentLeadsTableBroker = ({ leads }) => {
	const [timeFrame, setTimeFrame] = useState("This Quarter");
	const [filteredLeads, setFilteredLeads] = useState([]);

	useEffect(() => {
		setFilteredLeads(filterLeadsByTimeFrame(leads, timeFrame));
	}, [leads, timeFrame]);

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	const data = useMemo(
		() =>
			filteredLeads.map((lead) => ({
				...lead,
				dateSubmitted: formatDate(lead.dateCreated),
			})),
		[filteredLeads]
	);

	const columns = useMemo(
		() => [
			{ accessorKey: "id", header: "Lead ID" },
			{ accessorKey: "category", header: "Insurance Category" },
			{ accessorKey: "cover", header: "Insurance" },
			{
				accessorKey: "customerName",
				header: "Customer Name",
				cell: (info) => <div style={{ width: "180px" }}>{info.getValue()}</div>,
			},
			{
				accessorKey: "phoneNumber",
				header: "Phone No.",
				cell: (info) => <div style={{ width: "120px" }}>{info.getValue()}</div>,
			},
			{ accessorKey: "email", header: "Email" },
			{ accessorKey: "dateSubmitted", header: "Date Submitted" },
			{ accessorKey: "status", header: "Status" },
		],
		[]
	);

	const table = useReactTable({
		data,
		columns,

		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	// Calculate the items being shown
	const { pageIndex, pageSize } = table.getState().pagination;
	const totalItems = data.length;
	const startItem = pageIndex * pageSize + 1;
	const endItem = Math.min((pageIndex + 1) * pageSize, totalItems);

	return (
		<div style={{ borderRadius: "12px" }} className="bg-white p-4">
			<Row className="justify-content-between mb-3">
				<Col>
					<h2 className=" fs-4">Recent Leads</h2>
				</Col>
				<Col className="text-end">
					<DropdownButton
						variant="outline-schema-grey"
						size="xs"
						title={timeFrame}
						id="dropdown-menu-align-right"
						onSelect={(eventKey) => setTimeFrame(eventKey)}
					>
						<Dropdown.Item eventKey="This Month">This Month</Dropdown.Item>
						<Dropdown.Item eventKey="Last Month">Last Month</Dropdown.Item>
						<Dropdown.Item eventKey="This Quarter">This Quarter</Dropdown.Item>
					</DropdownButton>
				</Col>
			</Row>
			<div
				className=""
				style={{
					border: "1px solid #D6D6D6 ",
					borderRadius: "13px",
					overflow: "hidden",
				}}
			>
				<Table className="mb-0" hover borderless size="lg">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr style={{ backgroundColor: "#F1F4F9" }} key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										className="p-3"
										style={{ backgroundColor: "#F1F4F9" }}
										key={header.id}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
											  )}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row, index) => (
							<tr
								style={{
									borderBottom:
										index !== table.getRowModel().rows.length - 1
											? "1px solid #D6D6D6"
											: "",
								}}
								key={row.id}
							>
								{row.getVisibleCells().map((cell) => (
									<td className=" px-3 text-schema-grey " key={cell.id}>
										<Link
											className="w-100 d-block py-4  h-100"
											to={`/broker/lead-info?leadId=${row.original.airtableId}`}
											style={{ textDecoration: "none", color: "inherit" }}
										>
											{cell.column.id === "status" ? (
												<span
													className="p-2 w-100 Jakarta-Bold "
													style={{
														display: "inline-block",

														color: "#fff",
														textAlign: "center",

														borderRadius: "100px", // Rounded corners
														backgroundColor:
															cell.getValue() === "Assigned"
																? "#2DAF49"
																: "#1470D6",
													}}
												>
													{cell.getValue() || "Not Assigned"}
												</span>
											) : (
												<span className="py-2 d-inline-block">
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext()
													)}
												</span>
											)}
										</Link>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</div>
			{filteredLeads.length > 0 && (
				<div className=" mt-3 d-flex justify-content-between align-items-center">
					<span className="text-schema-grey">
						<p>
							Showing {startItem}-{endItem} of {totalItems}
						</p>
					</span>
					<Pagination>
						<Pagination.Prev
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
						/>
						<Pagination.Next
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
						/>
					</Pagination>
				</div>
			)}
			{filteredLeads.length < 1 && (
				<div className=" mt-4 text-center">
					<p className="fs-4 pb-0 mb-0">No Leads Available</p>
				</div>
			)}
		</div>
	);
};

const filterLeadsByTimeFrame = (leads, timeFrame) => {
	const now = new Date();
	let startOfPeriod;
	let endOfPeriod = new Date(); // Default to now for simplicity

	switch (timeFrame) {
		case "This Month":
			startOfPeriod = new Date(now.getFullYear(), now.getMonth(), 1);
			break;
		case "Last Month":
			startOfPeriod = new Date(now.getFullYear(), now.getMonth() - 1, 1);
			endOfPeriod = new Date(now.getFullYear(), now.getMonth(), 0);
			break;
		case "This Quarter":
			const quarter = Math.floor(now.getMonth() / 3);
			startOfPeriod = new Date(now.getFullYear(), quarter * 3, 1);
			endOfPeriod = new Date(now.getFullYear(), quarter * 3 + 3, 0);
			break;
		default:
			return leads; // Return all leads by default
	}

	return leads.filter((lead) => {
		const leadDate = new Date(lead.dateCreated);
		return leadDate >= startOfPeriod && leadDate <= endOfPeriod;
	});
};

export default RecentLeadsTableBroker;
